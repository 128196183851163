import { BaseResponse, C6BaseResponse, C6PageRequest, C6PageResponse, PageRequest } from '@/types/API'
import request, { c6Request } from '@/utils/request'

// /** 获取开票申请列表 */
// export function getOrderInvoiceList(params: PageRequest<AnyObject>) {
//   return request.get<BaseResponse>('invoice/order-invoice-list', { params })
// }

/** 获取开票申请列表 */
export function findInvoiceApplyPage(params: C6PageRequest<InvoiceApplyPageQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>('backstage/invoice/findInvoiceApplyPage', { params })
}

// /** 根据订单号计算开票金额 */
// export function calcOrderInvoiceAmount(data: number[]) {
//   return request.post<BaseResponse<number>>('invoice/calc-order-invoice-amount', data)
// }

/** 根据订单号计算开票金额 */
export function calcOrderInvoiceAmount(data: number[]) {
  return c6Request.post<C6BaseResponse<number>>('backstage/invoice/getInvoiceAmountByOrderIds', data)
}

/** 回填开票信息 */
export function orderInvoiceBackfill(data: InvoiceBackfillData) {
  return c6Request.post<C6BaseResponse>('backstage/invoice/invoiceFillBack', data)
}

/** 已开发票明细列表 */
export function getInvoiceDetailList(params: C6PageRequest<QueryInvoiceDetailListParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>('backstage/invoice/findTaxedInvoiceDetailPage', { params })
}

// /** 批量获取胶合板厂支付路径 */
// export function getOrderPayProcess(data: number[]) {
//   return request.post<BaseResponse<AnyArray>>('invoice/order-pay-flow', data)
// }

/** 批量获取订单支付路径 */
export function getOrderPayProcess(data: number[]) {
  return c6Request.post<C6BaseResponse<AnyArray>>('backstage/orderflow/getOrderPayFlow', data)
}

/** 查询已回填发票分页 */
export function getInvoicePage(params: C6PageRequest<InvoiceListPageQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>('backstage/invoice/getInvoicePage', { params })
}

/** 修改发票信息，或重新绑定发票申请 */
export function rebindInvoiceInfo(data: InoviceRebindData) {
  return c6Request.post<C6BaseResponse<C6PageResponse>>('backstage/invoice/rebindInvoiceInfo', data)
}

export interface InvoiceApplyPageQueryParams {
  applyEndTime?: Date,
  applyStartTime?: Date,
  areaCode?: string,
  invoiceCode?: string,
  invoiceStatus?: number,
  jhbcName?: string,
  orderNo?: string,
  xqjzName?: string,
  bpaIds?: number[],
  bpaIdsStr?: string,
  invoiceId?: number
}

export interface QueryOrderInvoiceListParams {
  OrderNo?: string,
  JhbcName?: string,
  XqjzName?: string,
  ApplyStartTime?: Date,
  ApplyEndTime?: Date,
  InvoiceStatus?: number,
  InvoiceCode?: string,
  AreaCode?: string
}

export interface InvoiceBackfillData {
  invoiceCode: string,
  invoiceDate: Date,
  invoiceUser: string,
  amountMoney: number,
  orderInvoiceIds: number[]
}

export interface QueryInvoiceDetailListParams {
  orderCode?: string,
  applySn?: string,
  applyTimeBegin?: Date,
  applyTimeEnd?: Date,
  invoiceCode?: string,
  invoiceDateBegin?: Date,
  invoiceDateEnd?: Date,
  invoiceTitle?: string,
  jhbName?: string,
}

export interface InvoiceListPageQueryParams {
  invoiceCode?: string,
  signStatus?: number,
  invoiceDateStart?: Date,
  invoiceDateEnd?: Date
}

export interface InoviceRebindData {
  ilid: number,
  invoiceCode: string,
  invoiceDate: Date,
  invoiceUser: string,
  amountMoney: number,
  orderInvoiceIds: number[]
}