
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { defineComponent, nextTick, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, SpanMethod, TableColumns } from '@/types/BasicComponent'
import { formatTime, formatDate } from '@/utils/common'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { getInvoiceDetailList } from '@/api/invoice'
import { QueryInvoiceDetailListParams } from '@/api/invoice'
import * as math from "mathjs"

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<QueryInvoiceDetailListParams>({})
    const tableData = ref<AnyArray>([])
    const selectCount = ref(0)
    const summaryData = ref<AnyArray>([0,0,0,0,0,0,0,0,0])
    summaryData.value = summaryData.value.map(() => math.chain(math.bignumber(0)))

    const exportRef = ref()

    const getTableData = async () => {
      const resp = await getInvoiceDetailList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      const respData = resp.data.data.records

      tableData.value = respData
      tableData.value.forEach((td, idx) => td.index = pagination.pageOffset + idx)

      summaryData.value = summaryData.value.map(() => math.chain(math.bignumber(0)))
      tableData.value.forEach(td => {
        summaryData.value[0] = summaryData.value[0].add(td.invoiceSum)
        summaryData.value[1] = summaryData.value[1].add(td.freeTaxSum)
        summaryData.value[2] = summaryData.value[2].add(td.taxSum)
        summaryData.value[3] = summaryData.value[3].add(td.serviceSum)
      })

      pagination.total = resp.data.data.total
    }
    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
    })

    const getSummaries = (param: AnyObject) => {
      const columnIndexsToSummary = [6,7,8,9]

      const summaryArray: AnyArray = []
      columnIndexsToSummary.forEach((idx, i) => {
        summaryArray[idx] = summaryData.value[i].done().toFixed(2)
      })

      summaryArray[0] = '本页小计'

      return summaryArray
    }

    const searchInputs = reactive<Inputs>([
      {
        label: '订单号',
        type: 'input',
        placeholder: '请输入订单号',
        model: toRef(searchData, 'orderCode'),
        width: '180px',
        clearable: true,
      }, {
        label: '客户名称',
        type: 'input',
        placeholder: '请输入客户名称',
        model: toRef(searchData, 'jhbName'),
        width: '180px',
        clearable: true,
      }, {
        label: '发票号码',
        type: 'input',
        placeholder: '请输入发票号码',
        model: toRef(searchData, 'invoiceCode'),
        width: '150px',
        clearable: true
      }, {
        label: '申请流水号',
        type: 'input',
        placeholder: '请输入申请流水号',
        model: toRef(searchData, 'applySn'),
        width: '150px',
        clearable: true
      }, {
        label: '申请日期',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'applyTimeBegin'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'applyTimeEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }, {
        label: '开票日期',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'invoiceDateBegin'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'invoiceDateEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: async () => exportRef.value.doExport()
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        label: '序号',
        width: '100px',
        align: 'center'
      }, {
        prop: 'jhbName',
        label: '客户名称',
        minWidth: '150px'
      }, {
        prop: 'orderCode',
        label: '订单号',
        minWidth: '175px',
        preventFormat: true
      }, {
        prop: 'applySn',
        label: '申请流水号',
        minWidth: '175px',
        preventFormat: true
      }, {
        prop: 'applyTime',
        label: '申请日期',
        minWidth: '150px',
        type: 'render',
        render: scope => formatDate(scope.row.applyTime)
      }, {
        prop: 'order_Code',
        label: '产品名称',
        minWidth: '200px',
        type: 'render',
        render: scope => {
          let goodsNames = ''
          const goodsInfoList = scope.row.goodsInfoList
          goodsInfoList.forEach((gi: any, idx: number) => {
            if (idx > 0) goodsNames += '|'
            goodsNames += (gi.categoryName + gi.goodsName)
          })
          return goodsNames
        }
      }, {
        prop: 'invoiceSum',
        label: '发票金额',
        minWidth: '120px',
      }, {
        prop: 'freeTaxSum',
        label: '不含税金额',
        minWidth: '120px',
      }, {
        prop: 'taxSum',
        label: '税额',
        minWidth: '120px',
      }, {
        prop: 'serviceSum',
        label: '服务费金额',
        minWidth: '120px',
      }, {
        prop: 'invoiceDate',
        label: '审核日期',
        minWidth: '150px',
        type: 'render',
        render: scope => formatDate(scope.row.invoiceDate)
      }, {
        prop: 'invoiceStatus',
        label: '开票状态',
        minWidth: '100px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '未开票', value: 1, type: 'info' },
          { label: '已开票', value: 2, type: 'success' },
          { label: '已申请', value: 3, type: 'warning' },
        ]
      }, {
        prop: 'invoiceDate',
        label: '开票日期',
        minWidth: '150px',
        type: 'render',
        render: scope => formatDate(scope.row.invoiceDate)
      }, {
        prop: 'invoiceCode',
        label: '发票号码',
        minWidth: '150px',
        preventFormat: true
      }, {
        prop: 'signStatus',
        label: '签收状态',
        minWidth: '100px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '待签收', value: null, type: 'warning' },
          { label: '待签收', value: 0, type: 'warning' },
          { label: '已签收', value: 1, type: 'success' },
        ]
      }, {
        prop: 'signTime',
        label: '签收日期',
        minWidth: '150px',
        type: 'render',
        render: scope => formatDate(scope.row.signTime)
      }
    ])

    return {
      getTableData,
      formatTime,
      formatDate,
      getSummaries,
      exportRef,
      searchInputs,
      searchData,
      searchButtons,
      tableColumns,
      pagination,
      tableData,
      UserRole,
      selectCount
    }
  }
})
